import {Linking, Platform, StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {Highfive} from '@components/highfive';
import {BottomButton} from '@components/BottomButton';
import {setHomeURL} from "@utils/url";
import {getBundleId} from 'react-native-device-info';
import {useTranslation} from "react-i18next";

export const DownloadAppPage = ({navigation}: any) => {
  const {t} = useTranslation('recipient');
  const update = () => {
    const bundleId = getBundleId();
    if (Platform.OS === 'ios' || window.navigator.userAgent.includes('Safari')) {
      const link = `https://apps.apple.com/es/app/kanguro/id6448806330?l=es`;
      Linking.canOpenURL(link).then(supported => {
        if (supported) {
          Linking.openURL(link);
        } else {
          console.log("Don't know how to open URI: " + link);
        }
      });
    } else {
      const link = 'https://play.google.com/store/apps/details?id=com.kanguro.client';
      Linking.canOpenURL(link).then(supported => {
        if (supported) {
          Linking.openURL(link);
        } else {
          console.log("Don't know how to open URI: " + link);
        }
      });
    }
  };
  const download = () => {
    update();
  };

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{flex: 0.9}}>
        <View style={{paddingHorizontal: 20, marginTop: 50}}>
          <Text style={styles.title}>{t('download_title')}</Text>
          <Text style={styles.text}>
            {t('download_description')}
          </Text>
        </View>
        <Highfive />
      </View>
      <BottomButton onPress={download} text={t('download_action')} />
    </View>
  );
};
const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '900',
    fontFamily: 'Poppins-Bold',
    lineHeight: 36,
    textAlign: 'left',
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
    lineHeight: 24,
    textAlign: 'left',
    color: '#9796A1',
  },
});
