import {Stack} from '@components/Stack';
import {ProgressBar, Text} from 'react-native-paper';
import {Linking, StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import {useAuth} from '@auth/useAuth';
import React, {useEffect, useState} from 'react';
import Toast from 'react-native-toast-message';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import {KTextInput} from '@components/KTextInput';
import {Formik, useFormik} from "formik";
import {SignatureRequest} from "@modules/invitation/features/resources/Signature.request";
import {Signature} from "@components/signature/Signature";
import {useNavigation} from "@react-navigation/native";
import {EditProfileApiRequest} from "@modules/profile/features/edit-profile/resources/EditProfile.apiRequest";
import {EditProfileResource} from "@modules/profile/features/edit-profile/resources/EditProfile.resource";
import {SignatureResource} from "@modules/invitation/features/resources/Signature.resource";
import {LinePoints, SignatureProvider} from "@components/signature/Signature.provider";
import {theme} from "@config/Theme";
import {BottomButton} from "@components/BottomButton";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface Props {
  counterId: string;
  containsUps: boolean;
}

export const SignatureFeature = ({counterId, containsUps}: Props) => {
  const {t} = useTranslation('invitation');
  const {user} = useAuth();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const [containsUpsParcels, setContainsUpsParcels] = useState<boolean | null>(containsUps);
  const [signatureNotTouched, setSignatureNotTouched] = useState<boolean>(false);

  const form = useFormik({
    initialValues: SignatureRequest.getInitialValues({phone: user.profile.phone, ...user?.profile}),
    validationSchema: SignatureRequest.getValidationSchema(),
    onSubmit<Values>(values: Values) {
      setLoading(true);
      submit(values);
    },
  });

  useEffect(() => {
    AsyncStorage.getItem('invitation_ids').then((value) => {
      if (!value) return;
      try {
        const ids = JSON.parse(value!)
        setContainsUpsParcels(ids.containsUpsParcels)
        form.setFieldValue('containsUpsParcels', ids.containsUpsParcels)
      } catch (error) {
        window.location.reload()
      }
    })
  }, [])

  const openTermsAndConditions = () => {
    Linking.openURL('https://kanguro.com/terminos-y-condiciones/');
  };

  const openPrivacyPolicy = () => {
    Linking.openURL('https://kanguro.com/politica-de-privacidad/');
  };

  const submit = (values: any) => {
    setLoading(true);
    submitUpdate(values)
  };

  const getSignature = (file: File | null, signaturePoints: Array<LinePoints>) => {
    if (signaturePoints.length !== 0) {
      setSignatureNotTouched(false);
    }
    form.setFieldValue('signature', file);
    form.setFieldValue('signaturePoints', signaturePoints);
  }

  const showTooManyRequests = () => {
    Toast.show({
      type: 'error',
      text1: t('signature_error')!,
      text2: t('signature_requirement')!,
    });
  }

  const submitUpdate = (values: any) => {
    const formData = new FormData();
    if (values.signature === null) {
      showTooManyRequests();
      return;
    }
    formData.append('file', values.signature);
    SignatureResource.uploadSignature(formData)
      .then((SignatureResponse) => {
        const data = new EditProfileApiRequest({...values, signature: SignatureResponse.data.url});
        EditProfileResource.save(data)
          .then(() => {
            //@ts-ignore
            navigation.navigate('Receive', {
              counterId: counterId,
              containsUpsParcels: containsUps,
              signed: true,
            });
          })
          .catch(() => {
            Toast.show({
              type: 'error',
              text1: t('signature_error')!,
              text2: t('signature_error_message')!,
            });
          })
          .finally(() => setLoading(false));
      })
  }

  if (loading || containsUpsParcels === null || containsUpsParcels === undefined) {
    return (
      <ProgressBar indeterminate />
    );
  }

  return (
    <>
      <Formik
        initialValues={form}
        onSubmit={values => {
          setLoading(true);
          submit(values);
        }}>
        <TouchableWithoutFeedback>
          <View style={{justifyContent: 'space-between', flex: 1}}>
            <View
              style={{
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}>
              <Stack spacing={2}>
                <BodyGray>
                  {t('signature_description')}
                </BodyGray>
                <KTextInput
                  label="Nombre"
                  name="name"
                  form={form}
                />
                <KTextInput
                  label="Email"
                  textContentType="emailAddress"
                  autoCapitalize="none"
                  name="email"
                  form={form}
                />
                {
                  !containsUpsParcels &&
                    <KTextInput
                      label="NIF"
                      name="dni"
                      form={form}
                    />
                }
                <SignatureProvider>
                  <Signature
                    getSignatureFile={getSignature}
                    name={containsUpsParcels ? '' : 'signaturePoints'}
                    form={form}
                    error={signatureNotTouched}
                  />
                </SignatureProvider>
              </Stack>
              <Text style={styles.information}>
                {t('signature_form_agreements_1')} {' '}
                <Text style={styles.textlink} onPress={openTermsAndConditions}>
                  {t('signature_form_agreements_2')}
                </Text>{' '}
                {t('signature_form_agreements_3')}
                <Text style={styles.textlink} onPress={openPrivacyPolicy}>
                  {' '}
                  {t('signature_form_agreements_4')}
                </Text>
              </Text>
            </View>
            <BottomButton onPress={() => {
              if (form.values.signaturePoints.length === 0) {
                setSignatureNotTouched(true);
                return;
              }
              form.handleSubmit();
            }} text={t('signature_form_submit')} />
          </View>
        </TouchableWithoutFeedback>
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  information: {
    color: theme.colors.info,
    fontSize: 12,
    fontFamily: 'Poppins-Regular',
  },
  textlink: {
    fontSize: 11,
    color: '#9796A1',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  }
});
