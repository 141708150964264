import {View} from 'react-native';
import Onomatopeia from '@assets/img/high-five/onomatopeya.png';
import HighFiveLeft from '@assets/img/high-five/highFiveLeft.png';
import HighFiveRight from '@assets/img/high-five/highFiveRight.png';
import {animated, useSpring} from '@react-spring/web'

export const Highfive = () => {
  const springsTop = useSpring({
    from: { y: 110 },
    to: { y: 70 },
  })
  const springsLeft = useSpring({
    from: { x: -30, y: 120 },
    to: { x: 25, y: 120 },
  })
  const springsRight = useSpring({
    from: { x: 10, y: 120 },
    to: { x: -15, y: 120 },
  })
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
      <animated.img
        src={HighFiveLeft}
        style={{
          width: 250,
          top: 70,
          borderRadius: 8,
          ...springsLeft,
        }}
      />
      <animated.img
        src={Onomatopeia}
        style={{
          top: 70,
          borderRadius: 8,
          ...springsTop,
        }}
      />
      <animated.img
        src={HighFiveRight}
        style={{
          width: 250,
          top: 70,
          borderRadius: 8,
          ...springsRight,
        }}
      />
    </View>
  );
};
